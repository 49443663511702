import React from "react"
import { graphql, PageProps } from "gatsby"
import Seo from "@/components/seo"
import PageHeading from "@/components/PageHeading"
import IHeading from "@/components/IHeading"
import IParagraph from "@/components/IParagraph"
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined"
import RecordVoiceOverOutlinedIcon from "@material-ui/icons/RecordVoiceOverOutlined"
import RateReviewOutlinedIcon from "@material-ui/icons/RateReviewOutlined"
import SecurityOutlinedIcon from "@material-ui/icons/SecurityOutlined"
import CodeOutlinedIcon from "@material-ui/icons/CodeOutlined"
import { StaticImage } from "gatsby-plugin-image"
import ILine from "@/components/ILine"
import ImportContactsOutlinedIcon from "@material-ui/icons/ImportContactsOutlined"
import FormatListNumberedRtlOutlinedIcon from "@material-ui/icons/FormatListNumberedRtlOutlined"
import DoneAllOutlinedIcon from "@material-ui/icons/DoneAllOutlined"
import SurroundSoundOutlinedIcon from "@material-ui/icons/SurroundSoundOutlined"
import TransformOutlinedIcon from "@material-ui/icons/TransformOutlined"
import Layout from "@/components/Layout"
import CallToActionMini from "@/components/CallToActionMini"
import OurProcess from "@/components/OurProcess"
import PageIntro from "@/components/PageIntro"
import "./styles.css"


const ReadyToGetStarted = () => {
    const readyItems = [
      {
        icon: <DashboardOutlinedIcon />,
        text: "Cloud infrastructure management",
      },
      {
        icon: <RecordVoiceOverOutlinedIcon />,
        text: "24x7x365 support",
      },
      {
        icon: <RateReviewOutlinedIcon />,
        text: "Comprehensive monitoring and testing",
      },
      {
        icon: <SecurityOutlinedIcon />,
        text: "Security and compliance",
      },
      {
        icon: <CodeOutlinedIcon />,
        text: "DevOps services",
      },
    ]
  
    return (
      <section className="w-full my-20">
        <div className="container mx-auto">
          <div className="max-w-5xl" data-aos="fade-up">
            <IParagraph>
              We're cloud support experts specializing in digital transformation,
              Kubernetes implementation, microservice development, cloud
              migration, and DevOps. When you work with us, we will help you
              identify your current situation, what goals and pain points you have
              with your current systems, and then work with you to develop a
              custom cloud migration plan that will meet your needs. We don't just
              want to give you a one-size-fits-all solution—we want to help you
              develop a plan that's going to put your problems into the past and
              set you up for long-term success.
            </IParagraph>
          </div>
          <div className="max-w-5xl mx-auto my-16">
            <div className="flex flex-col gap-y-4" data-aos="fade-up">
              <IHeading align="text-center">Ready to get started?</IHeading>
              <IParagraph align="text-center">
                We have a passion for helping the world's largest enterprises get
                the most out of their cloud technology, with services that
                include:
              </IParagraph>
            </div>
          </div>
          <div
            className="grid max-w-6xl grid-cols-1 mx-auto overflow-visible rounded-md shadow-none md:grid-cols-5 gap-y-4 md:shadow-lg md:overflow-hidden"
            data-aos="fade-up"
          >
            {readyItems.map((item: any, index: number) => {
              return (
                <div
                  className="relative flex flex-col items-center p-5 bg-white rounded-md shadow-xl group ready-started-box dark:bg-primary-900 gap-y-5 duraction-300 hover:bg-slate-600 hover:duration-300 hover:rounded-md md:shadow-none md:rounded-0"
                  key={index}
                >
                  {item.icon}
                  <IParagraph size="subHeader" align="text-center">
                    {item.text}
                  </IParagraph>
                  <div className="divider absolute top-0 bottom-0 -right-0.5 my-auto w-1 h-16 bg-slate-400 z-10 hidden md:block"></div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    )
  }

  const WhyUsStep = () => {
    const whyUsItems = [
      "Implementing best practices",
      "Tailored Solutions",
      "Reliable Services",
    ]
  
    return (
      <section className="w-full mt-28">
        <div className="container flex flex-col items-center mx-auto">
          <div data-aos="fade-up">
            <IHeading align="text-center">Why Us</IHeading>
          </div>
          <div
            className="relative flex mt-10 cursor-default gap-x-0 md:gap-x-28"
            data-aos="fade-up"
          >
            {whyUsItems.map((item: any, index: number) => {
              return (
                <div
                  className="z-10 flex flex-col items-center w-32 group gap-y-5"
                  key={index}
                >
                  <p className="text-center text-gray-400 duration-300 group-hover:text-slate-600 dark:group-hover:text-primary-100 group-hover:duration-300">
                    {item}
                  </p>
                  <div className="flex items-center justify-center w-6 h-6 duration-300 bg-white border-2 border-gray-300 rounded-full group-hover:border-slate-600 group-hover:duration-300">
                    <span className="w-3 h-3 duration-300 bg-gray-300 rounded-full dark:bg-primary-500 group-hover:bg-slate-600 dark:group-hover:bg-primary-800 group-hover:duration-300"></span>
                  </div>
                </div>
              )
            })}
            <div className="why-us-divider absolute h-1 left-0 right-0 bottom-2.5 mx-auto bg-gray-300 z-0"></div>
          </div>
        </div>
      </section>
    )
  }

  const WhyUsBlock = () => {
    const whyUsBlockItems = [
      {
        title: "Implementing best practices",
        content:
          "Whether you`re just starting out with cloud computing or have been using it for years, our experts can make sure that your environment is optimized for what you`re trying to do. We do this by implementing best practices and focusing on automation, so that we can deliver the best solution possible in a timely manner.",
      },
      {
        title: "Tailored Solutions",
        content:
          "We know that every business is different, and so are their needs. That`s why we work with each client individually to create systems tailored specifically for them.",
      },
      {
        title: "Reliable Services",
        content:
          "When you need reliable cloud-based services, Easesol is the best choice. Easesol is a cloud support company that focuses on providing the best possible service to our customers. We`re always ready to help you with your unique needs, and we`ll work with you to achieve the right solution for your business without any hassle or worry.",
      },
    ]
  
    return (
      <section className="w-full my-28">
        <div className="container mx-auto">
          <div className="flex flex-col mt-8 gap-y-16 md:gap-y-28 md:mt-14">
            {whyUsBlockItems.map((item: any, index: number) => {
              return (
                <div
                  className={`flex flex-col ${
                    index % 2 === 1 ? "md:flex-row-reverse" : "md:flex-row"
                  } gap-x-10 xl:gap-x-20 gap-y-8`}
                  key={index}
                >
                  <div
                    className="w-full overflow-hidden rounded-lg shadow-xl md:w-2/4"
                    data-aos="fade-up"
                  >
                    {index === 0 ? (
                      <StaticImage
                        src={`../images/cloud-support/cloud-1.svg`}
                        alt={`cloud-1`}
                        className="object-cover w-full h-full"
                      />
                    ) : index === 1 ? (
                      <StaticImage
                        src={`../images/cloud-support/cloud-2.svg`}
                        alt={`cloud-2`}
                        className="object-cover w-full h-full"
                      />
                    ) : index === 2 ? (
                      <StaticImage
                        src={`../images/cloud-support/cloud-3.svg`}
                        alt={`cloud-3`}
                        className="object-cover w-full h-full"
                      />
                    ) : (
                      <StaticImage
                        src={`../images/cloud-support/cloud-1.svg`}
                        alt={`cloud-1`}
                        className="object-cover w-full h-full"
                      />
                    )}
                  </div>
                  <div className="flex flex-col justify-center w-full gap-y-4 md:w-2/4">
                    <div data-aos="fade-up">
                      <IHeading size="subTitle">{item.title}</IHeading>
                    </div>
                    <ILine align="left" />
                    <div data-aos="fade-up">
                      <IParagraph>{item.content}</IParagraph>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    )
  }

  const WeWillHelpYou = () => {
    const helpItems = [
      {
        icon: <ImportContactsOutlinedIcon />,
        title: "Protect your IT solution",
        content:
          '"Need to protect your IT infrastructure? We have got you covered. Our solutions help secure your entire IT system, ensuring your data and assets are safeguarded."',
      },
      {
        icon: <FormatListNumberedRtlOutlinedIcon />,
        title: "Reinforce your skills",
        content:
          "Need to strengthen your skills? Let us help! Our services provide targeted reinforcement to boost your expertise.",
      },
      {
        icon: <DoneAllOutlinedIcon />,
        title: "Overcome complex issues",
        content:
          "Facing complex issues? Let us help! Our team has the expertise and experience to guide you through even the toughest challenges and find a solution.",
      },
      {
        icon: <SurroundSoundOutlinedIcon />,
        title: "Reduce TCO of IT systems",
        content:
          "Looking to reduce the total cost of ownership(TCO) of your IT system? We can help! Our solutions and services can help you streamline your system and lower your TCO.",
      },
      {
        icon: <TransformOutlinedIcon />,
        title: "Transfer and manage duties and risks",
        content:
          "Need help transferring and managing duties and risk? Our Team can provide guidance and support to ensure a smooth and successful transition.",
      },
    ]
  
    return (
      <section className="w-full pt-12 pb-24 my-20 bg-gray-800 lg:my-36 md:pt-24 dark:bg-primary-900">
        <div className="container mx-auto">
          <div data-aos="fade-up">
            <IHeading align="text-center" color="white">
              We will help you
            </IHeading>
          </div>
          <div className="grid grid-cols-1 mt-20 md:grid-cols-2 lg:grid-cols-3 gap-x-10 xl:gap-x-20 gap-y-20">
            {helpItems.map((item: any, index: number) => {
              return (
                <div
                  className="relative p-6 pt-10 duration-300 border-4 border-gray-600 group md:p-8 lg:pt-16 hover:border-white"
                  key={index}
                  data-aos="fade-up"
                >
                  <div className="absolute px-4 bg-gray-800 help-icon-box dark:bg-primary-900 -top-10">
                    {item.icon}
                  </div>
                  <IHeading size="subHeader" color="white">
                    {item.title}
                  </IHeading>
                  <p className="mt-5 text-gray-400 duration-300 group-hover:text-gray-200">
                    {item.content}
                  </p>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    )
  }
interface CloudSupportPageData {
  mdx: {
    body: string
    excerpt: string
    frontmatter: {
      title: string
      heading: string
      iconName: string
      color?: string
    }
  }
}

const CloudSupportPage: React.FC<PageProps<CloudSupportPageData>> = ({ data }) => {
  const { frontmatter,  excerpt } = data.mdx
  const { title, heading } = frontmatter

  return (
    <div>
      <Layout>
      <Seo title={title + ` | Services`} />
      <div data-aos="fade-up">
        <PageHeading title={title} description={heading}></PageHeading>
      </div>
      <div data-aos="fade-up">
        <PageIntro>{excerpt}</PageIntro>
      </div>
      <ReadyToGetStarted />
      <WhyUsStep />
      <WhyUsBlock />
      <WeWillHelpYou />
      <OurProcess />
      <CallToActionMini
        title="Hire Us"
        description="Want to ensure your application is running smoothly and always available? Let us work for you to monitor performance and availability."
        redirectTo="/hire-us"
      />
    </Layout>
    </div>
  )
}

export const query = graphql`
  query CloudSupportPageQuery {
    mdx(slug: { eq: "cloud-support" }) {
      body
      excerpt(pruneLength: 160)
      frontmatter {
        title
        heading
        iconName
        color
      }
    }
  }
`

export default CloudSupportPage